@import "styles/utils.module";
@import "styles/color.module";


.listContainer {
  @extend .flexRow;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.listContainer::-webkit-scrollbar {
  display: none;
}

.cardWrapper {
  text-align: left;
  flex: 0 0 70px;
  padding-left: 8px;

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    padding-right: 16px;
  }
}

.showMoreContainer {
  flex: 0 0 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 39px;
  cursor: pointer;
}

.showMoreText {
  width: 100%;
  margin-top: 12px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
}
