@import "styles/utils.module";
@import "styles/color.module";


.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brandImage {
  position: relative;
  width: 70px;
  height: 70px;
  border: 1px solid #EDEDED;
  border-radius: 9999px;
  overflow: hidden;
}

.defaultCircle {
  background-color: $gray200;
}

.brandName {
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
}

.brandNameText {
  width: 65px;
  word-wrap:break-word;
  text-align: center;
}
