@import "styles/utils.module";
@import "styles/color.module";

.postsWrapper {
  @extend .flexRow;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  & > *+* {
    margin-left: 4px;
  }
}

.cardWrapper {
  text-align: left;
  flex: 0 0 135px;
}

.showMoreContainer {
  flex: 0 0 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.showMoreText {
  width: 100%;
  margin-top: 12px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $gray800;
}

.horizontalScrollPaddingLeft {
  flex: 0 0 $layoutLeftPaddingNew - 4px;
}

.horizontalScrollPaddingRight {
  flex: 0 0 $layoutRightPaddingNew - 4px;
}
